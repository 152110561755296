import React from "react"
import { graphql } from "gatsby"
import { BlogLayout } from "../components/layouts"
import SEO from "../components/seo"
import { VideoPlayer } from "../components/video"
import { ScrollToButton } from "../components/buttons"
import "./video.scss"

const VideoTemplate = props => {
  const post = props.data.markdownRemark

  return (
    <BlogLayout>
      <SEO
        title={post.frontmatter.titletag}
        ogDescription={post.frontmatter.description}
      />
      <article className="blog-post">
        <h1>{post.frontmatter.title}</h1>
        <ScrollToButton
          id={`a${post.frontmatter.youtube_id}`}
          content="Go To Video"
          style={{ marginBottom: "20px" }}
        />
        <section
          className="markdown"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <VideoPlayer youtubeId={post.frontmatter.youtube_id} />
      </article>
    </BlogLayout>
  )
}

export default VideoTemplate

export const pageQuery = graphql`
  query VideoBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        titletag
        author
        youtube_id
      }
    }
  }
`
