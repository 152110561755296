import React from "react"
import { Button } from "semantic-ui-react"

export default function ScheduleVisitButton() {
  return (
    <Button
      as="a"
      href="https://calendly.com/corey-14/30min"
      target="_blank"
      className=""
      content="Schedule A Free LTC Consultation"
      size="tiny"
      icon="calendar"
    />
  )
}
