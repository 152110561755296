import React from "react"

export default function VideoPlayer({ youtubeId }) {
  // youtube id sometimes isnt a valid html id
  // so to make it an always valid id, we need to add a letter
  // to the beginning

  return (
    <div
      id={`a${youtubeId}`}
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        width="100%"
        height="600"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
      ></iframe>
    </div>
  )
}

VideoPlayer.defaultProps = {
  youtubeId: "",
}
