import React from "react"
import { Button } from "semantic-ui-react"

export default function LiveEventButton() {
  return (
    <Button
      as="a"
      href="https://www.eventbrite.com/e/atlanta-breakfast-and-educational-workshop-on-long-term-care-tickets-166840268735"
      target="_blank"
      className=""
      content="Click Now To Register"
      size="tiny"
      icon="calendar"
    />
  )
}
