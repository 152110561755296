import React, { Component } from "react"
import { Link } from "gatsby"
import { Button } from "semantic-ui-react"

export default function RegisterForWebinarButton() {
  return (
    <Button
      as="a"
      href="/long-term-care-webinar"
      className=""
      content="Register for our Free Webinar"
      size="tiny"
      icon="video"
    />
  )
}
